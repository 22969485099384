@import '../theme-bootstrap';

.engraving-preview-popup {
  background-position: 0;
  border: 0;
  margin: 0 auto;
  overflow: hidden;
  z-index: 9999;
  @include breakpoint($landscape-up) {
    padding: 0;
  }
  @if $text-direction == rtl {
    direction: ltr;
  }
  $close-button-sizeval: 28;
  $close-button-thickval: 2;
  $close-button-sizepx: $close-button-sizeval * 1px;
  $close-button-bleft: ($close-button-sizeval / 2) * 1px;
  $close-button-bheight: ($close-button-sizeval + 1) * 1px;
  #cboxClose {
    #{$rdirection}: 20px;
    background: none transparent;
    border: none;
    height: $close-button-sizepx;
    padding: 14px 13px;
    position: absolute;
    text-indent: -9999px;
    top: 19px;
    width: $close-button-sizepx;
    &:before,
    &:after {
      #{$ldirection}: $close-button-bleft;
      background-color: $black;
      content: ' ';
      height: $close-button-bheight;
      position: absolute;
      top: 0;
      width: $close-button-thickval * 1px;
      @include breakpoint($landscape-up) {
        background-color: $white;
        @if $text-direction == rtl {
          background-color: $black;
        }
      }
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
    @include breakpoint($landscape-up) {
      #{$rdirection}: 13px;
      background: none transparent;
      top: 13px;
    }
  }
  #cboxLoadedContent {
    padding: 0;
  }
}

.spp-engraving-preview {
  background: $color-cream;
  display: flex;
  flex-direction: column;
  height: inherit;
  justify-content: center;
  margin: 0 auto;
  min-height: 500px;
  width: 100%;
  @include breakpoint($landscape-up) {
    float: $ldirection;
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    background: $black url('/media/export/cms/engraving/rotate.png') no-repeat scroll center 0 /
      cover !important;
    height: 100%;
    width: 100%;
  }
  @include breakpoint($landscape-up) {
    display: block;
    height: 100%;
    min-height: unset;
    padding: 0;
  }
  .engraving-form-element-preview {
    display: flex;
    justify-content: center;
  }
  h3 {
    display: block;
    font-size: 15px;
    padding: 0;
  }
  .background-swap,
  .background-prod-image {
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
      background: url('/media/export/cms/engraving/rotate.png') no-repeat scroll 0 0 / cover $black !important;
      height: 100%;
      width: 100%;
    }
  }
  .background-prod-image {
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 100%;
    transition: background-image 1s ease-in-out;
    width: 100%;
    @include breakpoint($landscape-down) {
      background: $color-cream !important;
    }
  }
  .background-prod-image-inner {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    transition: background-image 1s ease-in-out;
    width: 100%;
    @include breakpoint($landscape-up) {
      background-image: none !important;
    }
  }
  .background-swap {
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    transition: background-image 1s ease-in-out;
    width: 100%;
    @include breakpoint($landscape-up) {
      height: 100%;
      overflow: hidden;
    }
    .overlay-left-arrow-nav {
      #{$ldirection}: 20px;
      position: absolute;
      top: 22px;
      @include breakpoint($landscape-up) {
        #{$ldirection}: 14px;
        text-indent: -9999px;
        top: 14px;
      }
      .back-choices {
        align-items: center;
        display: flex;
        height: 28px;
        justify-content: center;
        position: relative;
        text-indent: -9999px;
        width: 25px;
        &:after {
          #{$ldirection}: 4px;
          border-#{$rdirection}: 2px solid $black;
          border-bottom: 2px solid $black;
          content: '';
          display: inline-block;
          height: 18px;
          position: relative;
          top: unset;
          transform: rotate(-225deg);
          width: 18px;
        }
      }
    }
    .engraving-form-preview {
      display: none;
      @include breakpoint($landscape-up) {
        display: block;
      }
      .new-canvas {
        background-size: cover;
        height: 250px;
        margin-top: 20px;
        margin: 0 auto;
        width: 280px;
        @include breakpoint($landscape-up) {
          background: unset;
          height: unset;
          margin: unset;
          width: unset;
        }
        .engrave-placement {
          @include breakpoint($landscape-down) {
            display: block;
            height: 100%;
            position: relative;
            width: 100%;
          }
        }
      }
    }
  }
  .engrave-placement {
    color: $white;
    display: block;
    position: absolute;
    top: 0;
    width: 180px;
    @include breakpoint($landscape-up) {
      top: 405px;
    }
    input {
      color: $white;
      font-size: 2.2em;
      letter-spacing: normal;
      padding: 0;
      text-align: center;
    }
    .canvas-orig {
      background: none transparent;
      border: none;
      display: block;
      font-size: 3em;
      margin: 0;
      padding: 5px;
      position: absolute;
      top: 0;
    }
    .engraving-form-choice-1 & {
      #{$ldirection}: 0;
      height: 100px;
      top: 0;
      @include breakpoint($landscape-up) {
        #{$ldirection}: 530px;
        top: 420px;
      }
    }
    .engraving-form-choice-2 & {
      height: 100px;
    }
    .engraving-form-choice-4 &,
    .engraving-form-choice-5 & {
      #{$ldirection}: 0;
      font-style: 1em;
      overflow: hidden;
      top: 0;
      width: 200px;
      @include breakpoint($landscape-up) {
        #{$ldirection}: 510px;
        top: 405px;
      }
    }
    .engraving-form-choice-4 & {
      height: 100px;
    }
    .engraving-form-choice-5 & {
      height: 50px;
    }
  }
  .engraving-form-choice-1 {
    &.background-swap {
      min-height: 490px;
      @include breakpoint($landscape-up) {
        min-height: unset;
      }
      .background-prod-image {
        background-color: $white;
        @include breakpoint($landscape-up) {
          background-image: url('/media/export/cms/engraving/product/926x541/jo_100ml_926x541_l.jpg') !important;
        }
        .new-canvas {
          @include breakpoint($landscape-down) {
            background-image: url('/media/export/cms/engraving/product/478x426/jo_100ml_478x426_l.jpg') !important;
          }
        }
      }
    }
  }

  // 30ml
  .engraving-size-30ml {
    &.background-swap {
      background-image: url('/media/export/cms/engraving/product/926x541/jo_30ml_926x541_b.jpg');
      .new-canvas {
        @include breakpoint($landscape-down) {
          background-image: url('/media/export/cms/engraving/product/478x426/jo_30ml_478x426_b.jpg');
        }
      }
      &.engraving-form-choice-1 {
        .background-prod-image {
          background-color: $white;
          @include breakpoint($landscape-up) {
            background-image: url('/media/export/cms/engraving/product/926x541/jo_30ml_926x541_l.jpg') !important;
          }
        }
        .new-canvas .background-prod-image-inner {
          @include breakpoint($landscape-down) {
            background-image: url('/media/export/cms/engraving/product/478x426/jo_30ml_478x426_l.jpg') !important;
          }
        }
      }
      &.engraving-form-choice-2 {
        background-image: url('/media/export/cms/engraving/product/926x541/jo_30ml_926x541_b.jpg');
        .new-canvas {
          @include breakpoint($landscape-down) {
            background-image: url('/media/export/cms/engraving/product/478x426/jo_30ml_478x426_b.jpg');
          }
        }
      }
      &.engraving-form-choice-4 {
        .background-prod-image {
          @include breakpoint($landscape-down) {
            background-color: $white;
            background-image: none !important;
          }
        }
      }
    }
    .engraving-form-choice-1,
    .engraving-form-choice-2 {
      .engrave-placement {
        // ASMBLY5-669 - No RTL style required. keep same placment for RTL locales
        left: 0;
        height: 100%;
        top: 0;
        width: 100%;
      }
    }
    // 30ml - engrave lid
    .engraving-form-choice-1 {
      .engrave-placement {
        @include breakpoint($landscape-up) {
          // No RTL style required
          left: 560px;
          height: 45px;
          top: 420px;
          width: 115px;
        }
        .engrave-choice-script,
        .engrave-choice-block {
          height: 50px;
        }
        input {
          font-size: 20px;
          &.engrave-choice-script {
            margin-#{$ldirection}: -8px;
            padding: 0;
            top: 125px;
            width: 100%;
            @include breakpoint($landscape-up) {
              margin-#{$ldirection}: -5px;
              top: 2px;
            }
          }
          &.engrave-choice-block {
            letter-spacing: 2px;
            margin-#{$ldirection}: -8px;
            top: 120px;
            width: 100%;
            @include breakpoint($landscape-up) {
              margin-#{$ldirection}: -2px;
              top: 0;
            }
          }
        }
      }
    }
    // 30ml - engrave bottle
    .engraving-form-choice-2 {
      .engrave-placement {
        @include breakpoint($landscape-up) {
          // No RTL style required
          left: 560px;
          height: 45px;
          top: 445px;
          width: 115px;
        }
        .engrave-choice-script,
        .engrave-choice-block {
          @include breakpoint($landscape-down) {
            height: auto;
            top: 60%;
          }
        }
        input {
          font-size: 23px;
          width: 100%;
          &.engrave-choice-script,
          &.engrave-choice-block {
            height: 50px;
            margin-#{$ldirection}: 0;
            margin-top: 0;
          }
          &.engrave-choice-script {
            height: 50px;
            letter-spacing: 0;
            margin-#{$ldirection}: -11px;
            @include breakpoint($landscape-up) {
              height: 100%;
              margin-#{$ldirection}: -2px;
              top: -5px;
            }
          }
          &.engrave-choice-block {
            letter-spacing: 2px;
            margin-#{$ldirection}: -7px;
            margin-top: -2px;
            @include breakpoint($landscape-up) {
              height: 100%;
              letter-spacing: 2px;
              margin-#{$ldirection}: 0;
              top: -2px;
            }
          }
        }
      }
    }
  }
  // 50ml
  .engraving-size-50ml {
    &.background-swap {
      // engrave lid
      &.engraving-form-choice-1 {
        .background-prod-image-inner {
          @include breakpoint($landscape-down) {
            background-image: none !important;
          }
        }
        .engrave-placement {
          // No RTL style required
          left: -14px;
          height: 50px;
          top: 114px;
          @include breakpoint($landscape-up) {
            // No RTL style required
            left: 529px;
            height: 100px;
            top: 452px;
          }
          input {
            &.engrave-choice-script {
              font-size: 2.4em;
              letter-spacing: 3px;
              padding-#{$ldirection}: 5%;
              width: 100%;
              @include breakpoint($landscape-up) {
                font-size: 28px;
                margin-#{$ldirection}: -6px;
                margin-top: 0;
                padding-#{$ldirection}: 0;
                top: -84px;
              }
            }
            &.engrave-choice-block {
              #{$ldirection}: 14px;
              font-size: 36px;
              letter-spacing: 3px;
              top: -3px;
              width: 100%;
              @include breakpoint($landscape-up) {
                #{$ldirection}: -3px;
                font-size: 34px;
                margin-top: 3px;
                padding-#{$ldirection}: 0;
                top: -88px;
              }
            }
          }
        }
      }
      // engrave bottle
      &.engraving-form-choice-2,
      &.engraving-form-choice-5 {
        .background-prod-image {
          @include breakpoint($landscape-down) {
            background-color: $white;
            background-image: none !important;
          }
        }
        .engrave-placement {
          // No RTL style required
          left: 0;
          top: 0;
          @include breakpoint($landscape-up) {
            // No RTL style required
            left: 505px;
            top: 425px;
          }
          .engrave-choice-script,
          .engrave-choice-block {
            height: 45px;
            @include breakpoint($landscape-down) {
              height: auto;
              top: 66%;
            }
          }
          input {
            bottom: 59px;
            padding: 0;
            text-shadow: 0 0 $white;
            width: 100%;
            &.engrave-choice-script {
              font-size: 10px;
              margin-#{$ldirection}: -3px;
              top: auto;
              @include breakpoint($landscape-up) {
                bottom: auto;
                font-size: 16px;
                margin-#{$ldirection}: 1px;
                top: 15px;
              }
            }
            &.engrave-choice-block {
              font-size: 11px;
              margin-#{$ldirection}: 0;
              top: auto;
              @include breakpoint($landscape-up) {
                bottom: auto;
                font-size: 13px;
                margin-#{$ldirection}: 1px;
                top: 16px;
              }
            }
          }
        }
      }
      &.engraving-form-choice-2 {
        .engrave-placement {
          input {
            &.engrave-choice-script {
              font-size: 24px;
              @include breakpoint($landscape-up) {
                font-size: 26px;
                margin-#{$ldirection}: 0;
                top: 17px;
              }
            }
            &.engrave-choice-block {
              #{$ldirection}: 5px;
              font-size: 30px;
              letter-spacing: 3px;
              top: 163px;
              width: 100%;
              @include breakpoint($landscape-up) {
                #{$ldirection}: 5px;
                font-size: 26px;
                top: 17px;
              }
            }
          }
        }
      }
    }
  }
  // 100ml
  .engraving-size-100ml {
    &.background-swap {
      background-image: url('/media/export/cms/engraving/product/926x541/jo_100ml_926x541_b.jpg');
      .new-canvas {
        @include breakpoint($landscape-down) {
          background-image: url('/media/export/cms/engraving/product/478x426/jo_100ml_478x426_b.jpg');
        }
      }
      &.engraving-form-choice-1 {
        .background-prod-image {
          background-color: $white;
          @include breakpoint($landscape-up) {
            background-image: url('/media/export/cms/engraving/product/926x541/jo_100ml_926x541_l.jpg') !important;
          }
        }
        .new-canvas .background-prod-image-inner {
          @include breakpoint($landscape-down) {
            background-image: url('/media/export/cms/engraving/product/478x426/jo_100ml_478x426_l.jpg') !important;
          }
        }
        .engrave-placement {
          // No RTL style required
          left: 0;
          top: 0;
          @include breakpoint($landscape-up) {
            #{$ldirection}: 515px;
            align-items: center;
            display: flex;
            justify-content: center;
            top: 340px;
          }
          input {
            height: 50px;
            letter-spacing: 0.5px;
            top: 107px;
            width: 100%;
            @include breakpoint($landscape-up) {
              height: 100px;
            }
            &.engrave-choice-script {
              font-size: 30px;
              padding-#{$ldirection}: 0;
              @include breakpoint($landscape-up) {
                font-size: 30px;
                padding-#{$ldirection}: 12px;
                top: -2px;
              }
            }
            &.engrave-choice-block {
              font-size: 37px;
              padding-#{$ldirection}: 2px;
              @include breakpoint($landscape-up) {
                font-size: 30px;
                padding-#{$ldirection}: 20px;
                top: 0;
              }
            }
          }
        }
      }
      &.engraving-form-choice-4,
      &.engraving-form-choice-5 {
        background-image: url('/media/export/cms/engraving/product/926x541/jo_100ml_926x541_b.jpg');
        .background-prod-image {
          @include breakpoint($landscape-down) {
            background-color: $white;
            background-image: none !important;
          }
        }
        .new-canvas {
          @include breakpoint($landscape-down) {
            background-image: url('/media/export/cms/engraving/product/478x426/jo_100ml_478x426_b.jpg');
          }
        }
        .engrave-placement {
          // No RTL style required
          left: 0;
          top: 0;
          @include breakpoint($landscape-up) {
            // No RTL style required
            left: 505px;
            top: 370px;
          }
          input {
            font-size: 10px;
            margin-#{$ldirection}: 5px;
            text-shadow: 0 0 $white;
            top: 140px;
            width: 100%;
            @include breakpoint($landscape-up) {
              font-size: 18px;
              height: 45px;
              margin-#{$ldirection}: 10px;
              top: 40px;
            }
            &.engrave-choice-script {
              @include breakpoint($landscape-up) {
                font-size: 16px;
              }
            }
            &.engrave-choice-block {
              font-size: 12px;
              @include breakpoint($landscape-up) {
                font-size: 18px;
                margin-#{$ldirection}: 11px;
              }
            }
          }
          #preview-4-2,
          #preview-4-2-shadow {
            margin-top: 7%;
            @include breakpoint($landscape-up) {
              margin-top: 25px;
            }
          }
        }
      }
    }
  }
  // 200ml
  .engraving-size-200ml {
    &.background-swap {
      &.engraving-form-choice-4 {
        .engrave-placement {
          @include breakpoint($landscape-up) {
            // No RTL style required
            left: 565px;
            top: 375px;
          }
          input {
            font-size: 10px;
            margin-#{$ldirection}: 4px;
            top: 100px;
            width: 100%;
            @include breakpoint($landscape-up) {
              font-size: 18px;
              height: 45px;
              top: 33px;
            }
            &.engrave-choice-script {
              @include breakpoint($landscape-up) {
                font-size: 18px;
              }
            }
            &.engrave-choice-block {
              font-size: 12px;
              @include breakpoint($landscape-up) {
                font-size: 18px;
              }
            }
          }
          #preview-4-2,
          #preview-4-2-shadow {
            margin-top: 10%;
            @include breakpoint($landscape-up) {
              margin-top: 33px;
            }
          }
        }
      }
    }
  }
  // 200g, 600g, 2_1kg
  .engraving-size-200g,
  .engraving-size-600g,
  .engraving-size-2_1kg {
    &.background-swap {
      &.engraving-form-choice-3 {
        .engraving-forms {
          padding-top: 20px;
          @include breakpoint($landscape-up) {
            padding-top: 0;
          }
        }
        .engraving-form-preview .engraving-form-heading {
          padding-bottom: 20px;
          @include breakpoint($landscape-up) {
            padding-bottom: 0;
          }
        }
        .engrave-placement {
          @include breakpoint($landscape-up) {
            // No RTL style required
            right: 52px;
            top: 465px;
            width: 359px;
          }
          input {
            #{$ldirection}: -5px;
            font-size: 12px;
            font-weight: bold;
            text-shadow: 1px 1px 10px $black;
            top: 186px;
            width: 100%;
            @include breakpoint($landscape-up) {
              font-size: 15px;
              top: 4px;
            }
            &.engrave-choice-block {
              font-size: 13.5px;
              @include breakpoint($landscape-up) {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
  // 200g
  .engraving-size-200g {
    &.background-swap {
      &.engraving-form-choice-3 {
        background-image: url('/media/export/cms/engraving/product/926x541/jo_200g_926x541_c.jpg');
        .background-prod-image-inner {
          background-position: center;
          background-size: 118%;
          @include breakpoint($landscape-up) {
            background-size: cover;
          }
        }
        .engrave-placement {
          input {
            font-size: 9.5px;
            @include breakpoint($landscape-up) {
              // No RTL style required
              left: -24px;
              font-size: 18px;
              top: -7px;
            }
            &.engrave-choice-block {
              font-size: 11px;
              @include breakpoint($landscape-up) {
                font-size: 18px;
              }
            }
          }
        }
        .close-container {
          background-image: url('/media/export/cms/engraving/custom_nav_x-dark.png');
        }
        .new-canvas {
          @include breakpoint($landscape-down) {
            background-image: url('/media/export/cms/engraving/product/478x426/jo_200g_478x426_c.jpg');
          }
        }
      }
    }
  }
  // 600g
  .engraving-size-600g {
    &.background-swap {
      &.engraving-form-choice-3 {
        background-image: url('/media/export/cms/engraving/product/926x541/jo_600g_926x541_c.jpg');
        .background-prod-image {
          background: none !important;
        }
        .new-canvas {
          @include breakpoint($landscape-down) {
            background-image: url('/media/export/cms/engraving/product/478x426/jo_600g_478x426_c.jpg');
          }
        }
        .engrave-placement {
          input {
            top: 202px;
            @include breakpoint($landscape-up) {
              top: 7px;
            }
          }
        }
      }
    }
  }

  // 2_5kg
  .engraving-size-2_5kg,
  .engraving-size-2500g {
    &.background-swap {
      &.engraving-form-choice-3 {
        background-image: url('/media/export/cms/engraving/product/926x541/jo_2500g_926x541_c.jpg');
        .close-container {
          background-image: url('/media/export/cms/engraving/custom_nav_x-dark.png');
        }
        .new-canvas {
          @include breakpoint($landscape-down) {
            background-image: url('/media/export/cms/engraving/product/478x426/jo_2500g_478x426_c.jpg');
          }
        }
        .engrave-placement {
          // No RTL style required
          left: 500px;
          height: 45px;
          top: 465px;
          width: 385px;
        }
      }
    }
    .engraving-form-choice-3 .engrave-placement {
      #preview-3-1,
      #preview-3-1-shadow {
        font-size: 1.8em;
        height: 100%;
        text-shadow: 1px 1px 1px $black;
        width: 100%;
        @include breakpoint($landscape-up) {
          font-size: 23px;
        }
      }
    }
  }

  // cat-CAT3640
  &.engraving-cat-CAT3640 {
    background-image: url('/media/export/cms/engraving/product/926x541/jo_200ml_BO_926x541_b.jpg');
    .background-swap {
      &.engraving-size-100ml,
      &.engraving-form-choice-4.engraving-size-100ml {
        background-image: url('/media/export/cms/engraving/product/926x541/jo_200ml_BO_926x541_b.jpg');
      }
      &.engraving-size-200ml .new-canvas,
      &.engraving-form-choice-4.engraving-size-200ml .new-canvas {
        @include breakpoint($landscape-down) {
          background-image: url('/media/export/cms/engraving/product/478x426/jo_200ml_BO_478x426_b.jpg');
        }
      }
    }
    .engraving-size-200ml {
      .engraving-form-choice-4 {
        .engrave-placement {
          // No RTL style required
          left: 0;
          height: 100%;
          top: 0;
          width: 100%;
          @include breakpoint($landscape-up) {
            // No RTL style required
            left: 565px;
            height: 100px;
            top: 415px;
            width: 185px;
          }
          #preview-4-1,
          #preview-4-1-shadow,
          #preview-4-2,
          #preview-4-2-shadow {
            width: 100%;
            @include breakpoint($landscape-down) {
              height: auto;
              top: 55%;
            }
          }
          #preview-4-2 {
            margin-top: 10%;
            @include breakpoint($landscape-up) {
              margin-top: 30px;
            }
          }
        }
      }
    }
    .new-canvas {
      @include breakpoint($landscape-down) {
        background-image: url('/media/export/cms/engraving/product/478x426/jo_200ml_BO_478x426_b.jpg');
      }
    }
  }
  // cat-CAT3553
  &.engraving-cat-CAT3553 {
    background-image: url('/media/export/cms/engraving/product/926x541/jo_100ml_CI_926x541_b.jpg');
    .new-canvas {
      @include breakpoint($landscape-down) {
        background-image: url('/media/export/cms/engraving/product/478x426/jo_100ml_CI_478x426_b.jpg');
      }
    }
    .background-swap {
      &.engraving-size-50ml {
        background-image: url('/media/export/cms/engraving/product/926x541/jo_50ml_CI_926x541_b.jpg');
        .new-canvas {
          @include breakpoint($landscape-down) {
            background-image: url('/media/export/cms/engraving/product/478x426/jo_50ml_CI_478x426_b.jpg');
          }
        }
      }
      &.engraving-size-100ml {
        background-image: url('/media/export/cms/engraving/product/926x541/jo_100ml_CI_926x541_b.jpg');
        .new-canvas {
          @include breakpoint($landscape-down) {
            background-image: url('/media/export/cms/engraving/product/478x426/jo_100ml_CI_478x426_b.jpg');
          }
        }
      }
      &.engraving-form-choice-1 {
        .background-prod-image {
          background-image: url('/media/export/cms/engraving/product/926x541/jo_100ml_CI_926x541_l.jpg') !important;
        }
        .new-canvas {
          .background-prod-image-inner {
            @include breakpoint($landscape-down) {
              background-image: url('/media/export/cms/engraving/product/478x426/jo_100ml_CI_478x426_l.jpg') !important;
            }
          }
        }
        &.engraving-size-50ml {
          .background-prod-image {
            background-image: url('/media/export/cms/engraving/product/926x541/jo_50ml_CI_926x541_l.jpg') !important;
          }
          .new-canvas {
            .background-prod-image-inner {
              @include breakpoint($landscape-down) {
                background-image: url('/media/export/cms/engraving/product/478x426/jo_50ml_CI_478x426_l.jpg') !important;
              }
            }
          }
        }
        &.engraving-size-100ml {
          .background-prod-image {
            background-image: url('/media/export/cms/engraving/product/926x541/jo_100ml_CI_926x541_l.jpg') !important;
          }
          .new-canvas {
            .background-prod-image-inner {
              @include breakpoint($landscape-down) {
                background-image: url('/media/export/cms/engraving/product/478x426/jo_100ml_CI_478x426_l.jpg') !important;
              }
            }
          }
        }
      }
      &.engraving-form-choice-4 {
        &.engraving-size-100ml {
          background-image: url('/media/export/cms/engraving/product/926x541/jo_100ml_CI_926x541_b.jpg');
          .new-canvas {
            @include breakpoint($landscape-down) {
              background-image: url('/media/export/cms/engraving/product/478x426/jo_100ml_CI_478x426_b.jpg');
            }
          }
        }
      }
      &.engraving-form-choice-5 {
        &.engraving-size-50ml {
          background-image: url('/media/export/cms/engraving/product/926x541/jo_50ml_CI_926x541_b.jpg');
          .new-canvas {
            @include breakpoint($landscape-down) {
              background-image: url('/media/export/cms/engraving/product/478x426/jo_50ml_CI_478x426_b.jpg');
            }
          }
        }
      }
    }
    .engraving-size-50ml,
    .engraving-size-100ml {
      .engraving-form-choice-1 {
        .engrave-placement {
          // No RTL style required
          left: 0;
          top: 0;
          @include breakpoint($landscape-up) {
            // No RTL style required
            left: 510px;
            top: 350px;
          }
        }
      }
    }
  }
  // cat-CAT11895
  &.engraving-cat-CAT11895 {
    .background-swap.engraving-form-choice-3 {
      background-image: url('/media/export/cms/engraving/product/478x426/jo_200g_CI_478x426_c.jpg');
      @include breakpoint($landscape-up) {
        background-image: url('/media/export/cms/engraving/product/926x541/jo_200g_CI_926x541_c.jpg');
      }
      &.engraving-size-200g {
        background-image: url('/media/export/cms/engraving/product/478x426/jo_200g_CI_478x426_c.jpg');
        @include breakpoint($landscape-up) {
          background-image: url('/media/export/cms/engraving/product/926x541/jo_200g_CI_926x541_c.jpg');
        }
        .close-container {
          background-image: url('/media/export/cms/engraving/custom_nav_x.png');
        }
      }
    }
  }
  .spp-engraving-form-content {
    align-items: flex-start;
    background: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0 35px;
    width: 100%;
    @include breakpoint($landscape-up) {
      align-items: center;
      background: $color-cream;
      padding: 0 20px;
      width: 405px;
    }
    input::-ms-clear {
      display: none;
    }
    .button {
      align-items: center;
      background: none transparent;
      border: 1px solid $color-grey-light;
      color: $black;
      display: flex;
      flex: 1;
      float: none;
      font: normal 1.2em/2 $font--lars;
      height: 32px;
      max-width: 60%;
      padding: 4%;
      text-align: center;
      text-decoration: none;
      width: 100%;
      word-spacing: 0.25em;
      @include breakpoint($landscape-up) {
        font-size: 14px;
        max-width: 100%;
        padding: 0 32px;
        width: 145px;
      }
      &:hover {
        text-decoration: none;
      }
      &.engraving-preview,
      &.engraving-cancel,
      &.engraving-save {
        border: none;
        font: normal normal 14px/1 $font--lars;
        justify-content: center;
        letter-spacing: unset;
        margin-#{$ldirection}: 20px;
        padding: 0;
        text-decoration: none;
        @include breakpoint($landscape-up) {
          display: inline-flex;
          margin: 0 10px;
          position: relative;
        }
      }
      &.engraving-cancel {
        background: none;
        border: none;
        color: $black;
        flex: 0 auto;
        font-size: 12px;
        margin-#{$ldirection}: 0;
        text-decoration: underline;
        width: auto;
        .engraving-form-choice-4.active-engraving-form & {
          @include breakpoint($landscape-up) {
            flex: 0;
            order: 2;
            padding: 0 20px;
          }
        }
      }
      &.engraving-save {
        background: $black;
        color: $white;
        flex: 1 auto;
        margin-#{$rdirection}: 0;
        max-width: 150px;
        padding: 5px 0;
        .engraving-form-choice-4.active-engraving-form & {
          @include breakpoint($landscape-up) {
            flex: 1 auto;
            padding: 0 20px;
          }
        }
      }
      &.engraving-preview {
        background: $black;
        color: $white;
        display: inline-flex;
        height: 36px;
        line-height: 1;
        margin-#{$ldirection}: 0;
        max-width: 100%;
        padding: 5px 0;
        @include breakpoint($landscape-up) {
          display: none;
        }
      }
      &.change-font {
        flex: 1;
        font-size: 20px;
        justify-content: center;
        margin: 0 10px;
        padding: 0 20px;
      }
      &.engraving-choice {
        font: normal 14px/1 $font--lars;
        height: auto;
        justify-content: center;
        padding: 10px 20px;
        text-transform: uppercase;
        &:hover {
          text-decoration: none;
        }
      }
      &.engrave-style-chosen {
        border-color: $black;
      }
      &.engrave-choice-script {
        font-family: $font--kuenstler;
        font-variant: unset;
        margin-#{$ldirection}: 0;
      }
      &.engrave-choice-block {
        font-family: $font--bernhard-bold;
        margin-#{$rdirection}: 0;
      }
    }
    .engraving-forms {
      margin: 0;
      padding-top: 80px;
      @include breakpoint($landscape-up) {
        padding-top: 0;
      }
      @if $text-direction == rtl {
        direction: rtl;
      }
    }
    .engraving-query {
      background-color: $color-cream;
      height: auto;
      margin: 0;
      width: 100%;
      @include breakpoint($landscape-up) {
        background-color: none;
        padding: 40px 20px;
        width: auto;
      }
    }
    .not-found {
      @include breakpoint($landscape-up) {
        @include swap_direction(margin, 45% 6% auto 15%);
        background-color: $black;
        color: $white;
        font-size: 20px;
        width: 50%;
      }
      p {
        @include breakpoint($landscape-up) {
          padding: 2%;
        }
      }
    }
    :focus {
      @include breakpoint($landscape-up) {
        outline: none;
      }
    }
  }
}

.engraving-form-element-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 15px 0;
  @include breakpoint($landscape-up) {
    clear: both;
    margin: 0 25px;
    padding: 0;
    text-align: center;
    width: 360px;
  }
  .preview-frame {
    border: 1px solid $white;
    height: 300px;
    width: 100%;
  }
}

.edit-engraving-form-cta {
  @include breakpoint($landscape-up) {
    margin-top: 17px;
    padding-bottom: 20px;
  }
  span {
    color: $black;
    display: block;
    font-family: $font--lars;
    font-size: 12px;
    line-height: 1.4em;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    @include breakpoint($landscape-up) {
      padding: 35px 20px 0;
    }
    .engraving-form-choice-4.active-engraving-form & {
      order: 3;
    }
  }
}

.engraving-form-line {
  align-items: center;
  display: inline-flex;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
  .char-counter {
    #{$rdirection}: 15px;
    font: 14px/1 $font--lars;
    position: absolute;
    top: 13px;
  }
  .chars-left {
    font-size: 12px;
  }
  input[type='text'] {
    @include input-placeholder {
      color: $color-primary-800;
      font-size: 14px;
      font-weight: normal;
      line-height: 3;
      position: relative;
      top: 1px;
    }
    background: $white;
    border: 1px solid $color-grey-light;
    font-size: 20px;
    font-weight: bold;
    height: 36px;
    padding: 0 16px;
    &.engraving-message,
    &.engrave-choice-script,
    &.engrave-choice-block {
      font: normal 14px/1 $font--lars;
      position: relative;
      top: 1px;
    }
  }
}

.engraving-form-heading {
  text-align: center;
  @include breakpoint($landscape-up) {
    margin: 0 auto;
  }
  h1,
  h2 {
    font: 14px/1 $font--lars;
  }
  h1 {
    font-weight: bold;
    letter-spacing: 0.5px;
    text-align: $ldirection;
    text-transform: uppercase;
    word-spacing: 3px;
  }
  h2 {
    margin-top: 20px;
  }
  h3 {
    font-weight: bold;
  }
  .engraving-edit-form & {
    height: 36px;
    padding-bottom: 0;
    @include breakpoint($landscape-up) {
      height: unset;
      padding-bottom: 25px;
    }
  }
  .engraving-query & {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    text-align: center;
    width: 100%;
  }
  .engraving-form-preview & {
    display: block;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  .engraving-form-custom-msg {
    display: none;
    font-size: 15px;
    margin-top: -15px;
    padding: 0 0 15px;
    width: 100%;
  }
}

.engraving-form-bottle .with-custom-msg {
  margin-top: -40px;
}

.engraving-label-wrapper {
  display: none;
}

.engraving-invite-txt {
  font: bold 13px/1.3 $font--lars;
  margin: 20px auto 30px;
  padding: 0;
  text-align: center;
  width: 100%;
  @include breakpoint($landscape-up) {
    font-weight: unset;
    margin: 40px auto 50px;
    width: 300px;
  }
}

.engraving-script,
.engrave-choice-script {
  font-family: $font--kuenstler;
}

.engraving-block,
.engrave-choice-block {
  font-family: $font--bernhard-bold;
  font-weight: bold;
}

.engraving-message {
  border: 1px solid $black;
  font-size: 1.1em;
  height: 36px;
  padding-#{$rdirection}: 50px;
  width: 100%;
  @include breakpoint($landscape-up) {
    @include swap_direction(padding, 5px 45px 5px 5px);
    display: block;
    font-size: 3em;
    height: 50px;
    width: 360px;
  }
}

.engraving-form-preview {
  .overlay-left-arrow-nav {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
    }
  }
}

.engraving-edit-form {
  width: 100%;
  @include breakpoint($landscape-up) {
    @include swap_direction(margin, 50px 10px 0 65px);
    float: $ldirection;
    margin: 25px 25px 0;
    width: 360px;
  }
  .engraving-form-lid & {
    @include breakpoint($landscape-up) {
      height: 170px;
    }
  }
}

.engraving-form-font-choice-btn {
  display: flex;
  height: auto;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;
  @include breakpoint($landscape-up) {
    margin-bottom: 15px;
  }
  &::after {
    clear: both;
    content: '.';
    display: block;
    height: 0;
    visibility: hidden;
  }
}

.engraving-form-element-subtotaling {
  display: block;
  padding-top: 20px;
}

.engraving-form {
  ul.subtotaling {
    clear: both;
    font: normal 1em/2em $font--lars;
    list-style: outside none none;
    padding-#{$ldirection}: 0;
    @include breakpoint($landscape-up) {
      font-size: 1.2em;
    }
    li {
      @include breakpoint($landscape-up) {
        color: $black;
        font-size: 13px;
        line-height: 2;
      }
      span.name {
        display: block;
        float: $ldirection;
        text-align: $ldirection;
        width: 80%;
      }
      span.numbers {
        display: block;
        float: $rdirection;
        text-align: $rdirection;
        width: 20%;
        @include breakpoint($landscape-up) {
          color: $black;
        }
      }
    }
  }
  ul.error_messages {
    width: 100%;
    @include breakpoint($landscape-up) {
      background: $black none repeat scroll 0 0;
      color: $white;
      font-size: 12px;
      font-weight: normal;
      padding: 0;
      position: absolute;
      top: 13%;
      width: 41%;
    }
  }
}

.engraving-form-choice-btn {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  .or-stmt {
    padding: 12px;
    text-transform: lowercase;
  }
}

@if $is-lengthy-engraving-cta == true {
  @include is_lengthy_engraving_cta;
}

@if $is-engraving-has-choice-8 == true {
  @include is-engraving-has-choice-8;
}
